import React from 'react';  
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';

class IndexElement extends React.Component
{
  render()
  {
    return (
      <div class="continer-fluid p-3" /* style={{backgroundImage: `url("/POS_Fulltable5.jpg")`}} */>
        {/* <img src='/POS_Fulltable5.jpg' style={{width:'100%'}}/> */}
        <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
        <img src='/POS_Fulltable5-min.jpg' style={{width:'100%'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
        <img src='/POS_Fulltable5-min.jpg' style={{width:'80%',margin:'auto'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
        <img src='/POS_Fulltable5-min.jpg' style={{width:'50%',margin:'auto'}}/>
        </Box>
        <div class='row'>
     <div class="col-md-12"> 
     <h2>Herzlich Willkommen im Point of Sale</h2>
     <p style={{color:'white'}}>Wir haben geschlossen! </p>
      <p style={{color:'white'}}> Wir bedanken uns für Ihre Treue!
</p>
     <p class="home">Wir sind bunt! Das bedeutet für uns Vielfalt und Freude, wenn man Ihr begegnet. So, wie es sich eben für einen richtigen „Point of Sale“ gehört. 😊 Wir laden euch ein, unsere kulinarische Vielfalt in gemütlicher Kaffeehausatmosphäre zu genießen! Wir bieten neben einem reichhaltigen Frühstücksangebot Traditionelles und Internationales, Vegetarisches und Veganes.</p>
<p class="home" style={{marginBottom:'100px'}}>Glutenfreies Gebäck, hausgemachte Bagels und Kuchen und die Verwendung von Bio Produkten (Bio-Rapsöl, Bio-Eier, Bio-Faschiertes etc…) runden unser Angebot ab. Wir freuen uns auf Euren Besuch!</p>
     
    </div> 
    </div>
    </div>
    )
  }
}
export default IndexElement;